<script setup>
const props = defineProps({
  card: Object
});

const btnText = props.card.attributes.btnText;
const mobileButtonText = ref(btnText);
const buttonText = ref(btnText);

onMounted(() => {
  const myCollapsible = document.getElementById(`cardDescription${props.card.id}`)
  myCollapsible.addEventListener('hidden.bs.collapse', function () {
    // Changing the text inside the button on mobile
    mobileButtonText.value = btnText
  })

  myCollapsible.addEventListener('shown.bs.collapse', function () {
    // Changing the text inside the button on mobile
    mobileButtonText.value = 'Hide Details'
  })
})

const copyDiscountCode = async (code, event) => {
  const copyButton = event.currentTarget; // Get the clicked button element
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(code);
      copyButton.textContent = 'Copied';
    } catch (error) {
      console.error('Clipboard writeText error:', error);
      copyFallback(code, copyButton);
    }
  } else {
    copyFallback(code, copyButton);
  }
};

const copyFallback = (code, copyButton) => {
  const tempInput = document.createElement('input');
  tempInput.value = code;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand('copy');
  document.body.removeChild(tempInput);
  copyButton.textContent = 'Copied';
};
</script>

<template>
  <div class="card w-100 mb-sm-0 mb-2">
    <div class="row g-0">
      <div class="col-4 col-md-3 img-wrapper p-3 p-md-0">
        <NuxtImg :src="card.attributes.image.url" class="w-100" />
      </div>
      <div class="col-8 col-md-9">
        <div class="card-body pt-3 pt-md-0 pe-3 pe-md-0">
          <h5 class="card-title">{{ card.attributes.title }}</h5>
          <article :class="['card-text', { 'd-md-none': card.attributes.mobileDescription }]" v-html="card.attributes.mobileDescription" v-if="card.attributes.mobileDescription"></article> 
          <article :class="['card-text', { 'd-none d-md-block': card.attributes.mobileDescription }]" v-html="card.attributes.description"></article>
          <p v-if="card.attributes.promoCode" class="mt-2"><strong>CODE: {{ card.attributes.promoCode }}</strong></p>
        </div>
      </div>
    </div>
    <div class="row g-0">
      <div class="col-10 button-wrapper col-md-9 offset-md-3 offset-1 pb-3 ps-md-2 d-flex justify-content-between flex-column flex-lg-row">
        <button class="btn btn-id-primary btn-sm copy-btn me-0 mb-4 mb-lg-0" @click="copyDiscountCode(card.attributes.discountCode, $event)" v-if="card.attributes.discountCode">Copy Code</button>
        <button class="btn btn-outline-secondary btn-sm d-none d-sm-block" data-bs-toggle="modal" :data-bs-target="'#details-' + card.id">{{ card.attributes.btnText }}</button>
        <article class="mb-2 collapse" :id="`cardDescription${card.id}`" v-html="card.attributes.longDescription"></article>
        <button class="btn btn-outline-secondary ms-0 ms-sm-2 btn-sm btn-mobile d-block d-sm-none" type="button" data-bs-toggle="collapse" :data-bs-target="`#cardDescription${card.id}`" aria-expanded="false" :aria-controls="`cardDescription${card.id}`">{{ mobileButtonText }}</button>
      </div>
    </div>
  </div>
  <Teleport to="body">
    <UIModal :modalId="'details-' + card.id" modalClass="modal-dialog-scrollable"> 
      <GenericContent :data="{content : card.attributes.longDescription}" v-if="card.attributes.longDescription"/>
    </UIModal>
  </Teleport>
  
</template>

<style lang="scss" scoped>
.card {
  max-width: 470px;
  border-radius: 24px;
  border: solid 1px $color-light-gray;

  

  img {
    border-radius: 12px;
    max-width: 100px;
  }

  @include media-breakpoint-up(md) {       
    padding: 30px;
  }

  .card-body {
    padding-left: 1rem;
    padding-right: 0px;
    @include media-breakpoint-down(md) {       
      padding-left: 0px;
    }
  }

  .button-wrapper button {
    font-size: 1rem;
  }
}
</style>