export const usePromotionData = () => {
  const config = useRuntimeConfig();
  const { sanitize } = useUtils();

  const dateToISOLocale = (date) => {
    return date.toLocaleString('sv').replace(' ', 'T');
  }

  const transform = (response) => {
    if (response.featuredPromo && Array.isArray(response.featuredPromo) && response.featuredPromo.length > 0) {
      // Find active promotions
      const activePromotions = response.featuredPromo.filter((promo) => {
        const currentDate = new Date(); // Current date in user's local time zone
      
        // New function dateToISOLocale added to convert date to ISO locale
        const startDate = promo.attributes.discountBannerStartDateTime;
        const endDate = promo.attributes.discountBannerEndDateTime;        
        const currentLocaleDate = dateToISOLocale(currentDate);
      
        return currentLocaleDate >= startDate && currentLocaleDate <= endDate;
      });
      
      // Check if there are multiple promotions with the same priority
      const uniquePriorities = Array.from(new Set(activePromotions.map((promo) => promo.attributes.priority)));
      if (activePromotions.length > 1 && uniquePriorities.length === 1 || activePromotions.length < 1) {
        // Clone the original response to avoid mutating it
        const newResponse = { ...response };

        // Replace the `featuredPromo` array with an empty array
        newResponse.featuredPromo = [];

        // Return the transformed response
        return {
          promotion: sanitize(newResponse),
        };
      } else if (activePromotions.length > 0) {
        // Sort promotions by priority
        activePromotions.sort((a, b) => b.attributes.priority - a.attributes.priority);

        // Clone the original response to avoid mutating it
        const newResponse = { ...response };

        // Replace the `featuredPromo` array with the highest priority active promotion
        newResponse.featuredPromo = [activePromotions[0]];

        // Return the transformed response
        return {
          promotion: sanitize(newResponse),
        };
      }
    }
    // No active promotions or only one promotion, return the original response
    return {
      promotion: sanitize(response),
    };
  };

  const getPromotion = async (deep = true) => {
    const url = `/api/promotion`;

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
        transform: transform,
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transform,
    });
  };

  const getPromoBannerSettings = async () => {
    const url = `/api/promo-banner`;

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
      });
    }

    return await useFetch(url, {
      method: 'GET',
    });
  };

  return {
    getPromotion,
    getPromoBannerSettings,
  };
};
